
#Contact-Box{
    display: flex;
    width: auto;
    height: auto;
    justify-content: space-between;
    align-items: center;
}

#Contact-Box #Header-Form-Box h3{
    font-size: 3rem;
    margin-bottom: 1rem;
}

#Contact-Box #Form-Box > div:not(:last-child){
    margin-bottom: 2rem;
}

#Contact-Box #Left-Box{
    width: 50%;
}

#Contact-Box #Right-Box{
    width: 40%;
}

#Contact-Box #Right-Box > img{
    width: 350px;
    margin-bottom: 3rem;
}

#Contact-Box #Form-Box{
    margin-top: 4rem;
}

#Contact-Box #Form-Box > div > h4{
    margin-bottom: .5rem;
    font-size: .95rem;
}

#Contact-Box #Circular-Progress-Box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    text-align: center;
}

@media screen and (max-width: 768px){
    #Contact-Box{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    #Contact-Box #Header-Form-Box h3{
        font-size: 2rem;
    }

    #Contact-Box #Contact-Options-Box{
        margin-top: 2rem;
    }

    #Contact-Box #Left-Box,
    #Contact-Box #Right-Box{
        width: 100%;
    }
}